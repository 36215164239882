export const investMerchantList = [
	'Walmart, WMT',
	"McDonald's, MCD",
	'Amazon, AMZN',
	'Apple, APLE',
	'Shell, SHEL',
	'Dollar General, DG',
	'Starbucks, SBUX',
	'Taco Bell, YUM',
	'Walgreens, WBA',
	'Burger King, QSR',
	'Popeyes, QSR',
	"Wendy's, WEN",
	'Kroger, KR',
	'Chevron, CVX',
	'Lyft, LYFT',
	'CVS, CVS',
	'Uber, UBER',
	'Uber Eats, UBER',
	'Speedway, SVNDF',
	'Sonic, SAH',
	'The Home Depot, HD',
	'BP Gas, BP',
	'Dollar Tree, DLTR',
	'Prime Video, AMZN',
	'Jack in the Box, JACK',
	'Amazon Prime Membership, AMZN',
	'ExxonMobil, XOM',
	"Casey's, CASY",
	'Safeway, SWY',
	'King Soopers, KR',
	'Sunoco, SUN',
	'eBay, EBAY',
	'Family Dollar, FDO',
	'Costco, COST',
	'Netflix, NFLX',
	'Chipotle, CMG',
	'ARCO, ARCE',
	'Spotify, SPOT',
	"Domino's, DPZ",
	"Lowe's, LOW",
	'Hulu, DIS',
	'CMS Vending, CMS',
	'Dairy Queen, BRK.A',
	"Arby's, WEN",
	'Murphy USA, MUSA',
	'AutoZone, AZO',
	'AT&T, T',
	'KFC, YUM',
	'Marathon, MRO',
	'Panera Bread, PNRA',
	'Costco (Gas), COST',
	'Etsy, ETSY',
	'Microsoft, MSFT',
	'Pizza Hut, YUM',
	'Kroger Fuel, KR',
	'Whole Foods, AMZN',
	"Sam's Club, WMT",
	'PlayStation, SONY',
	'Albertsons, ACI',
	'Ingles Markets, IMKTA',
	'Best Buy, BBY',
	'Geico, BRK.A',
	'Coca Cola, KO',
	'YouTube Premium, GOOGL',
	'Google Storage, GOOGL',
	'T-Mobile, TMUS',
	'Affirm, AFRM',
	'PayPal Pay in 4, PYPL',
	'Nintendo, NTDOY',
	'Comcast, CMCSA',
	'Verizon, VZ',
	'Phillips 66, PSX',
	'Stop & Shop, ADRNY',
	'Fred Meyer, KR',
	'Petco, WOOF',
	'Ross Dress for Less, ROST',
	"Papa John's, PZZA",
	"O'Reilly Auto Parts, ORLY",
	'Disney+, DIS',
	'Dillons, KR',
	'Google Fi, GOOGL',
	'Del Taco, TACO',
	'Spectrum, SPB',
	'Rite Aid, RAD',
	'AMC Theatres, AMC',
	'Amazon Digital, AMZN',
	'Five Below, FIVE',
	'Conoco, COP',
	'Wish, WISH',
	'IHOP, DIN',
	'Tesco, TSCDY',
	'Buffalo Wild Wings, BWLD',
	'Michaels, MIK',
	'Wingstop, WING',
	'Target, TGT',
	'Grubhub, GRUB',
	'Facebook, FB',
];
