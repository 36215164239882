import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { investMerchantList } from '../data/invest-merchants';
import hero from '../images/investments/merchants.png';
import searchImg from '../images/investments/search.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import {
	MerchantsImg,
	MerchantsImgContainer,
	MerchantsWrapper,
	MerchantsHeader,
	MerchantsSubHeader,
	Wrapper,
	MerchantsListWrapper,
	MerchantsListHeader,
	MerchantsListItem,
	MerchantsListBody,
	Body,
	MerchantsSearchWrapper,
	MerchantsSearchInput,
} from '../styles';

const InvestmentsMerchants = ({ data }) => {
	const [search, setSearch] = useState('');
	const [merchants, setMerchants] = useState({});

	useEffect(() => {
		createMerchants();
	}, [search]);
	console.log(Object.values(merchants).reduce((a, list) => (a = a + list.length), 0));

	const createMerchants = () => {
		// prettier-ignore
		const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
		let newMerchants = {};

		alphabet.forEach(letter => {
			const letterList = investMerchantList.filter(
				m => m.toLowerCase()[0] === letter && m.toLowerCase().includes(search.toLowerCase())
			);
			if (letterList.length > 0) newMerchants[letter] = letterList;
		});
		setMerchants(newMerchants);
	};

	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	const footerText = () =>
		data.allGoogleInvestingSheet.edges.find(({ node: { position } }) => position?.includes('footer-text'));

	const handleSearch = event => {
		const { value } = event.target;
		setSearch(value);
	};

	return (
		<Layout footerText={footerText()?.node?.text ?? null}>
			<MetaData
				title="Envel - Investment Merchants"
				description={`Envel Investment Merchants - ${metaDescription}`}
				image="images/social/socialInvestments.png"
			/>

			<MerchantsWrapper>
				<Wrapper>
					<MerchantsHeader>Top merchants</MerchantsHeader>
					<MerchantsSubHeader>
						Discover the top merchants that are involved in our automated investments feature.
					</MerchantsSubHeader>
				</Wrapper>
				<MerchantsImgContainer>
					<MerchantsImg src={hero} alt="hero" />
				</MerchantsImgContainer>
			</MerchantsWrapper>

			<br />
			<MerchantsSearchWrapper>
				<img src={searchImg} alt="search" />
				<MerchantsSearchInput
					placeholder="Search..."
					type="text"
					name="search"
					value={search}
					onFocus={e => {
						e.target.placeholder = '';
					}}
					onBlur={e => {
						e.target.placeholder = 'Search...';
					}}
					onChange={handleSearch}
				/>
			</MerchantsSearchWrapper>
			<br />

			{Object.keys(merchants).length > 0 ? (
				<MerchantsListWrapper length={Object.values(merchants).reduce((a, list) => (a = a + list.length), 0)}>
					{Object.entries(merchants).map(([key, value]) => (
						<MerchantsListItem key={key + value}>
							<MerchantsListHeader>{key.toUpperCase()}</MerchantsListHeader>
							{value.map(m => (
								<MerchantsListBody key={key + m}>{m}</MerchantsListBody>
							))}
						</MerchantsListItem>
					))}
				</MerchantsListWrapper>
			) : (
				<Body center>No matches for &apos;{search}&apos;</Body>
			)}

			<br />
			<br />
		</Layout>
	);
};

export default InvestmentsMerchants;

export const investmentsMerchantsQuery = graphql`
	query {
		allGoogleInvestingSheet {
			edges {
				node {
					position
					text
					text1
					text2
					text3
					text4
					text5
				}
			}
		}
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
				}
			}
		}
	}
`;
